@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body, 
html {
    font-family: 'Poppins' , 'sans-serif';
    /* font-size: 16px; */
    font-weight: 400;
    /* line-height: 1.5; */
     /* color: #000;
     background-color: #fff; */
}
input:focus,
textarea:focus,
select:focus{
    outline: 0 !important;
    box-shadow: none !important;
}
.hoveres{
    @apply hidden
}
.hovered:hover .hoveres{
    @apply flex-colo
}
.flex-colo {
    @apply flex flex-col justify-center items-center;
}
.flex-rows {
    @apply flex flex-row justify-center items-center;
}
.flex-btn{
    @apply flex justify-between items-center;
}
.transitions{
    @apply transition duration-300 ease-in;
}
.zIndex {
    z-index: 1000;
}
.compact-nav{
    transition:width .4s;
}
/* .sidebar-container {
    @apply bg-dry h-[96%] w-[20rem] rounded-3xl ml-6 p-4 border transition-all duration-500 border-solid border-glass relative
} */
.left-aligned-container {
    position: absolute; /* or use relative depending on your layout */
    left: 0;
    top: 50%; /* Adjust as needed */
    transform: translate(0%, -50%);
  }

.movie__detailRight {
  color: white;
  display: flex;
  flex-direction: column;
  height: 450px;
  justify-content:space-between ;
}
.movie__detailRightTop > div{
  text-shadow: 0px 0px 5px #000000;
  margin-bottom: .9rem;
}

.star-rating-container {
  display: flex;
  align-items: center;
}
.numeric-rating{
  font-size: 1.8rem;
  padding-right: 10px;
}
.star-rating {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

 .movie__detailRightBottom { 
  margin: 2rem 0;
  flex: 0.8;
}  

